// the status defines the visibility of logo or name with respect to header and footer
const STATUS_DISABLED = 0;
const STATUS_SHOW_ON_HEADER = 1;
const STATUS_SHOW_ON_FOOTER = 2;
const STATUS_SHOW_ON_HEADER_FOOTER = 3;
// const STATUS_DONT_SHOW_ON_HEADER_FOOTER = 4;

const COMMON_STATUS_SHOW = [STATUS_DISABLED, STATUS_SHOW_ON_HEADER_FOOTER];

export function getVisibilityForHeader(status: number): boolean {
  return [...COMMON_STATUS_SHOW, STATUS_SHOW_ON_HEADER].includes(status);
}

export function getVisibilityForFooter(status: number): boolean {
  return [...COMMON_STATUS_SHOW, STATUS_SHOW_ON_FOOTER].includes(status);
}
