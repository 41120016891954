import { IS_MOBILE } from './checkClientDeviceType';
import store from '../redux/store';
import { IS_SERVER, IS_WEBVIEW } from './checkRenderEnv';
import { PATH } from './routes';
import { MAX_VISIBLE_NAV_LINKS } from './constants';

export const isUserLoggedIn = () => !!store.getState().userReducer?.isLogin;

export const showMyAccount = () =>
  !IS_SERVER && IS_MOBILE && IS_WEBVIEW && isUserLoggedIn();

const myAccountNavData = {
  page_name: 'My Account',
  slug_url: PATH.MY_ACCOUNT,
  page_type: 'my-account',
};

const getNavList = (
  navigationItems,
  visibleItemsLength = MAX_VISIBLE_NAV_LINKS,
  showCategories = false
) => {
  const subNavObj = {
    page_type: 'subNavList',
    subNavs: [],
    is_editable: false,
    page_id: '616d2bd6152384798123d775',
    page_name: showCategories ? 'Categories' : 'More',
    slug_url: showCategories ? 'more' : 'subNavList',
  };

  const showMyAccountSection = showMyAccount();

  if (navigationItems?.length > 0) {
    let tempNavList = [];
    if (showCategories) {
      tempNavList = navigationItems.filter((nav) => nav.page_type !== 'shop');
    } else {
      tempNavList = [...navigationItems];
    }

    if (navigationItems?.length > visibleItemsLength) {
      const extractedNavList = tempNavList.splice(visibleItemsLength, tempNavList.length);
      subNavObj.subNavs = extractedNavList;
      if (showMyAccountSection) {
        subNavObj.subNavs.push(myAccountNavData);
      }
      const newNavList = [...tempNavList, subNavObj];
      return newNavList;
    } else {
      if (showMyAccountSection) {
        subNavObj.subNavs.push(myAccountNavData);
        tempNavList.push(subNavObj);
      }
      return tempNavList;
    }
  }
  return [];
};

export default getNavList;
