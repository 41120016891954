export const HeaderHome = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '16.983'}
      height={props.height || '16.984'}
      viewBox="0 0 16.983 16.984"
    >
      <path
        id="home"
        fill={props.color || '#000'}
        d="M17.026,7.387h0L10.1.458a1.563,1.563,0,0,0-2.211,0L.961,7.383.954,7.39a1.563,1.563,0,0,0,1.04,2.664l.048,0h.276v5.1a1.832,1.832,0,0,0,1.83,1.83H6.859a.5.5,0,0,0,.5-.5v-4a.836.836,0,0,1,.835-.835h1.6a.836.836,0,0,1,.835.835v4a.5.5,0,0,0,.5.5h2.711a1.832,1.832,0,0,0,1.83-1.83v-5.1h.256a1.564,1.564,0,0,0,1.107-2.669Zm-.7,1.507a.565.565,0,0,1-.4.167h-.754a.5.5,0,0,0-.5.5v5.6a.836.836,0,0,1-.835.835H11.62v-3.5a1.832,1.832,0,0,0-1.83-1.83h-1.6a1.832,1.832,0,0,0-1.83,1.83v3.5H4.148a.836.836,0,0,1-.835-.835v-5.6a.5.5,0,0,0-.5-.5H2.052a.568.568,0,0,1-.391-.97h0L8.589,1.162a.568.568,0,0,1,.8,0l6.926,6.926,0,0A.57.57,0,0,1,16.321,8.894Zm0,0"
        transform="translate(-0.499)"
      />
    </svg>
  );
};
