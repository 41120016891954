import checkIfShowDummyProduts from './checkIfShowDummyProducts';
import { DS_LOGO_IMAGE_URL } from './constants';

/**
 * function to get store logo in case of dummy or actual data
 * @param storeInfo
 * @returns
 */
const getActualOrDsStoreLogo = (storeInfo) => {
  const showDummyData = checkIfShowDummyProduts(storeInfo);
  return storeInfo?.store_info?.logo_image || (showDummyData ? DS_LOGO_IMAGE_URL : null);
};

export default getActualOrDsStoreLogo;
