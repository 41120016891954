import checkIfThemePreview from './checkIfThemePreview';
import { IS_SERVER } from './checkRenderEnv';
import { PREVIEW_PARAMS } from './constants';
import { getUrlParams } from './getUrlParams';

const checkIfShowDummyProduts = (storeData) => {
  const isPreview = checkIfThemePreview();
  const isPremium = !!storeData.premium;
  const { items_available } =
    !IS_SERVER && getUrlParams([PREVIEW_PARAMS.ITEMS_AVAILABLE]);
  const isItemsAvailable = +items_available;
  return isPreview && (!isPremium || !isItemsAvailable);
};

export default checkIfShowDummyProduts;
